import React from 'react';
import { Box, Button } from '@medely/ui-kit';
import useDialog from '@src/hooks/useDialog';
import { PositionsDetailsModal } from '../../../components/positionsDetailsModal/PositionsDetailsModal';
import { PositionProps } from '@src/components/positionsDetailsModal/interface';

export type PositionsDetailsModalButtonProps = {
  positions: PositionProps[];
};

export const PositionsDetailsModalButton = ({ positions }: PositionsDetailsModalButtonProps) => {
  const { open, openDialog, closeDialog } = useDialog();

  return (
    <Box>
      <Button variant="text" onClick={() => openDialog()}>
        Learn more about each position
      </Button>
      <PositionsDetailsModal positions={positions} open={open} onClose={closeDialog} />
    </Box>
  );
};
