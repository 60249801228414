import React from 'react';
import {
  Box,
  Button,
  Heading,
  HStack,
  MultiSelectField,
  MultiSelectFieldProps,
  VStack,
} from '@medely/ui-kit';
import {
  PositionsDetailsModalButton,
  PositionsDetailsModalButtonProps,
} from './components/PositionsDetailsModalButton';
import { FixedFooter } from '../../components/FixedFooterLayout/InternalFixedFooter';

type RecommendedPositionsProps = {
  allPositions: PositionsDetailsModalButtonProps['positions'];
  recommendedPositions: MultiSelectFieldProps['options'];
  handleContinueClick: () => void;
  handleBackClick: () => void;
};

export const RecommendedPositions = ({
  allPositions,
  recommendedPositions,
  handleBackClick,
  handleContinueClick,
}: RecommendedPositionsProps) => {
  return (
    <Box>
      <Heading size="s" color="text.primary">
        Select related positions to increase your earnings potential
      </Heading>
      <PositionsDetailsModalButton positions={allPositions} />
      <VStack gap={2}>
        <MultiSelectField
          variant="list"
          options={recommendedPositions}
          label="selectedRecommendedPositions"
          name="selectedRecommendedPositions"
        />
      </VStack>
      <FixedFooter>
        <HStack justifyContent="space-between">
          <Button fullWidth variant="outlined" onClick={handleBackClick}>
            Back
          </Button>
          <Box mx={1} />
          <Button fullWidth onClick={handleContinueClick}>
            Continue
          </Button>
        </HStack>
      </FixedFooter>
    </Box>
  );
};
