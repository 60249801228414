import { PERMISSIONS_QUERY } from '@medely/pro-multi-platform';
import { GraphQLProvider, GraphQLProviderInput, useFirebaseContext } from '@medely/ui-kit/web';
import isDevOrStaging from '@src/utils/isDevOrStaging';
import { useQuery } from '@tanstack/react-query';
import useSnackbar from 'hooks/useSnackbar';
import React, { createContext, useContext, type ReactNode } from 'react';
import { notifyRollbar } from 'utils/Rollbar/notifyRollbar';
import gqlRequest from 'utils/networkRequests/gqlRequest';

type RequestPermissions = Record<string, Record<string, boolean | string>>;

export const useGraphQLPermissions = () => {
  const { googleUser } = useFirebaseContext();

  const { data, ...props } = useQuery<RequestPermissions>(
    ['GetSchemaPermisionsForRequests'],
    async () => {
      const response = await gqlRequest(PERMISSIONS_QUERY);
      return response?.accountRequestPermissions;
    },
    { enabled: !!googleUser },
  );

  return { ...props, permissions: data, isLoading: props.isLoading && !!googleUser };
};

type GraphQLPermissionsContext = {
  isLoading: boolean;
  permissions: unknown;
};

const graphqlPermissionsContext = createContext<GraphQLPermissionsContext>({
  isLoading: true,
  permissions: undefined,
});

const GraphQLPermissionsContextProvider = ({ children }: { children: ReactNode }) => {
  const snackbar = useSnackbar();
  const { isLoading, permissions } = useGraphQLPermissions();

  const graphQLProviderProps: Omit<GraphQLProviderInput, 'children'> = {
    enableTransform: true,
    permissionsTransformer: {
      onTransform: (message: string) => {
        if (isDevOrStaging()) {
          const warning = `GraphQL Query transformed: ${message}`;

          snackbar.warning(warning);
          console.warn(warning);
        }

        notifyRollbar({
          errorLabel: 'GraphQL Query transformed',
          args: { message },
          level: 'WARNING',
        });
      },
      permissions,
    },
  };

  return (
    <graphqlPermissionsContext.Provider value={{ isLoading: isLoading, permissions }}>
      <GraphQLProvider {...graphQLProviderProps}>{children}</GraphQLProvider>
    </graphqlPermissionsContext.Provider>
  );
};

export const useGraphQLPermissionsContext = (): GraphQLPermissionsContext =>
  useContext(graphqlPermissionsContext);

export default GraphQLPermissionsContextProvider;
