import React from 'react';
import { Box, Button, Heading, HStack, MultiSelectField, SingleSelectField } from '@medely/ui-kit';
import { YEARS_OPTIONS } from './constants';
import { FixedFooterLayout } from '../../components/FixedFooterLayout';
import { IPosition } from '@medely/types';

type PositionItemProps = {
  position: Partial<IPosition>;
  positionIndex: number;
};

const PositionItem = ({ position, positionIndex }: PositionItemProps) => {
  const specialtyOptions =
    position?.specialties
      ?.filter(({ active }) => active)
      .map(({ id, label }) => ({ value: id.toString(), label })) || [];

  return (
    <Box mt={2}>
      <Heading size="xs" color="text.primary">
        {position?.display_name}
      </Heading>
      <Box>
        <Box mt={2}>
          <SingleSelectField
            name="professional_positions"
            label="Years of experience"
            options={YEARS_OPTIONS}
            parseValue={(value) => value.positions[positionIndex].years_of_experience}
            formatOnChange={(newValue, fieldValue) => {
              const newObject = { ...fieldValue };
              newObject.positions[positionIndex].years_of_experience = newValue;
              return newObject;
            }}
          />
        </Box>
        {!!specialtyOptions.length && (
          <Box mt={2}>
            <MultiSelectField
              name="professional_positions"
              label="Specialties"
              options={specialtyOptions}
              parseValue={(value) => value.positions[positionIndex].specialty_ids}
              formatOnChange={(newValue, fieldValue) => {
                const newObject = { ...fieldValue };
                newObject.positions[positionIndex].specialty_ids = newValue;
                return newObject;
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ClinicalExperienceAndSpecialties = ({ positions }: { positions: Partial<IPosition>[] }) => {
  return (
    <FixedFooterLayout
      footerContent={
        <HStack justifyContent="space-between">
          <Button fullWidth variant="outlined">
            Back
          </Button>
          <Box mx={1} />
          <Button fullWidth>Continue</Button>
        </HStack>
      }
    >
      <Heading size="s" color="text.primary">
        Select years of clinical experience and specialties
      </Heading>
      {positions?.map((position, idx) => (
        <PositionItem key={position.id} position={position} positionIndex={idx} />
      ))}
    </FixedFooterLayout>
  );
};

export default ClinicalExperienceAndSpecialties;
