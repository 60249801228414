import { IJob, IAssignment } from '@medely/types';
import { gql } from 'graphql-request';
import { isHolidayDifferential } from './jobUtils';
import { GraphQLFragment, DEFAULT_GRAPHQL_FRAGMENT } from './graphql';
import { isAgencyAccount, IsAgencyAccountInput } from './account';

const MAX_VISIBLE_BADGES = 3;

export enum BADGE_TYPE {
  great_pay = 'great-pay',
  missing_requirements = 'missing-requirements',
  holiday = 'holiday',
  bonus = 'bonus',
  distance = 'distance',
  travel_distance = 'travel-distance',
  first_to_apply = 'first-to-apply',
}

export type Badge = {
  variant: BADGE_TYPE;
  value?: number;
};

export interface IBadgedAssignment extends IAssignment {
  badges?: Badge[];
}

export interface IBadgedJob extends IJob {
  badges?: Badge[];
}

interface AssignmentQueriesProps {
  account?: IsAgencyAccountInput;
}

const filterBadges = (badges: Array<Badge | false>): Badge[] =>
  badges.filter((badge): badge is Badge => !!badge).slice(0, MAX_VISIBLE_BADGES);

export const badgedJobs = ({ jobs }: { jobs: IJob[] }): IBadgedJob[] =>
  jobs.map((job) => {
    const hasMissingRequirements = !job.can_book_job && !!job.missing_condition_ids?.length;
    const isGreatPay = job.great_pay;
    const isHoliday = job.job_price_differentials?.some(isHolidayDifferential);
    const shouldDisplayDistance = !!job.distance_in_miles;

    const badges = filterBadges([
      hasMissingRequirements && { variant: BADGE_TYPE.missing_requirements },
      isGreatPay && { variant: BADGE_TYPE.great_pay },
      isHoliday && { variant: BADGE_TYPE.holiday },
      shouldDisplayDistance && { variant: BADGE_TYPE.distance, value: job.distance_in_miles },
    ]);

    return { ...job, badges };
  });

export const localBadgedAssignments = ({
  assignments,
}: {
  assignments: IAssignment[];
}): IBadgedAssignment[] =>
  assignments.map((assignment) => {
    const hasBonus = !!assignment?.one_time_bonus_burden_cents;
    const hasMissingRequirements = !assignment.can_apply;
    const isGreatPay = assignment.great_pay;
    const distance = !!assignment.distance_in_miles;

    const badges = filterBadges([
      hasBonus && { variant: BADGE_TYPE.bonus, value: assignment.one_time_bonus_burden_cents },
      hasMissingRequirements && { variant: BADGE_TYPE.missing_requirements },
      isGreatPay && { variant: BADGE_TYPE.great_pay },
      distance && { variant: BADGE_TYPE.distance, value: assignment.distance_in_miles },
    ]);

    return { ...assignment, badges };
  });

export const travelBadgedAssignmentFragment = ({ account }: AssignmentQueriesProps) => {
  let TravelBadgedAssignmentConditionalFragment: GraphQLFragment | undefined =
    DEFAULT_GRAPHQL_FRAGMENT;

  // make appropriate fragment based on account type
  if (!isAgencyAccount(account)) {
    TravelBadgedAssignmentConditionalFragment = {
      name: '...TravelBadgedAssignmentConditionalFragment',
      fragment: gql`
        fragment travelBadgedAssignmentFragment on Assignment {
          one_time_bonus_burden_cents
          can_apply(professional_id: $professional_id)
          great_pay
          less_than_300_miles_away(professional_id: $professional_id)
          first_to_apply
          distance_in_miles(professional_id: $professional_id)
        }
      `,
    };
  }

  const TravelBadgedAssignmentFragment = {
    name: '...TravelBadgedAssignmentFragment',
    fragment: gql`
        ${TravelBadgedAssignmentConditionalFragment.fragment}

        fragment TravelBadgedAssignmentFragment on Assignment {
          ${TravelBadgedAssignmentConditionalFragment.name}
          __typename
        }
    `,
  };

  return { TravelBadgedAssignmentFragment };
};

export const travelBadgedAssignments = ({
  assignments,
}: {
  assignments: IAssignment[];
}): IBadgedAssignment[] =>
  assignments.map((assignment) => {
    const hasBonus = !!assignment?.one_time_bonus_burden_cents;
    const hasMissingRequirements = !assignment.can_apply;
    const isGreatPay = assignment.great_pay;
    const distance = assignment.less_than_300_miles_away;
    const isFirstToApply = assignment.first_to_apply;

    const badges = filterBadges([
      hasBonus && { variant: BADGE_TYPE.bonus, value: assignment.one_time_bonus_burden_cents },
      hasMissingRequirements && { variant: BADGE_TYPE.missing_requirements },
      isGreatPay && { variant: BADGE_TYPE.great_pay },
      distance && { variant: BADGE_TYPE.travel_distance },
      isFirstToApply && { variant: BADGE_TYPE.first_to_apply },
    ]);

    return { ...assignment, badges };
  });
