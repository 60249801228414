/* eslint-disable prettier/prettier */
import { gql } from 'graphql-request';
import {
  DEFAULT_GRAPHQL_FRAGMENT,
  type GraphQLFragment,
  type IsAgencyAccountInput,
  isAgencyAccount,
} from '../utils';

interface AssignmentQueriesProps {
  account?: IsAgencyAccountInput
}

export const getBookedAssignmentsGQL = ({ account }: AssignmentQueriesProps) => {
  let MarketplaceBookedAssignmentFragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  // make appropriate fragment based on account type
  if (!isAgencyAccount(account)) {
    MarketplaceBookedAssignmentFragment = {
      name: '...MarketplaceBookedAssignmentFragment',
      fragment: gql`
        fragment MarketplaceBookedAssignmentFragment on Assignment {
          id
        }
      `,
    };
  }

  // return gql query with fragment (if exists)
  return gql`
    ${MarketplaceBookedAssignmentFragment.fragment}

    query Assignments($input: AssignmentQueryInput) {
      assignments(input: $input) {
        ${MarketplaceBookedAssignmentFragment.name}
        id
        starts_date
        ends_date
        hours_per_shift
        number_of_shifts_per_week
        starts_time
        ends_time
        covid_care
        is_w2
        is_travel
        professional {
          account {
            first_name
            last_name
          }
        }
        status
        positions {
          display_name
        }
        jobs {
          id
          shift_type
          starts_date
          current_starts_time
          current_ends_time
          location {
            timezone_lookup
          }
        }
        primary_location {
          id
          name
          full_address
          timezone_lookup
          covid_requirement
          address_city
          address_zip
          state {
            covid_requirement
            abbreviation
          }
        }
        specialties {
          id
          label
        }
        onboarding_due_date
        cancellation_policy
        contract_total_hours
        guaranteed_hours_string
        float_requirements
        locations {
          name
          id
        }
        great_pay
        first_to_apply
        distance_in_miles
        less_than_300_miles_away
        missing_condition_ids
        one_time_bonus_burden_cents
        pay_per_week_cents
      }
    }
  `;
};

export const getOverlappingAssignmentsGQL = ({ account }: AssignmentQueriesProps) => {
  let GetOverlappingAssignmentsFragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  // make appropriate fragment based on account type
  if (!isAgencyAccount(account)) {
    GetOverlappingAssignmentsFragment = {
      name: '...GetOverlappingAssignmentsFragment',
      fragment: gql`
        fragment GetOverlappingAssignmentsFragment on Assignment {
          id
        }
      `,
    };
  }

   // return gql query with fragment (if exists)
  return gql`
    ${GetOverlappingAssignmentsFragment.fragment}

    query OverlappingAssignments($input: AssignmentQueryInput) {
      assignments(input: $input) {
        ${GetOverlappingAssignmentsFragment.name}
        id
      }
    }
  `;
};

export const getAvailableAssignmentsGQL = ({ account }: AssignmentQueriesProps) => {
  let GetAvailableAssignmentsFragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  // make appropriate fragment based on account type
  if (!isAgencyAccount(account)) {
    GetAvailableAssignmentsFragment = {
      name: '...GetAvailableAssignmentsFragment',
      fragment: gql`
        fragment GetAvailableAssignmentsFragment on Assignment {
          can_apply
          is_travel
          great_pay
          first_to_apply
          distance_in_miles
          less_than_300_miles_away
          primary_location {
            id
            name
            full_address
            timezone_lookup
            address_city
            address_zip
            location_type {
              label
            }
            covid_requirement
            state {
              abbreviation
              covid_requirement
            }
          }
          positions {
            display_name
            id
          }
          specialties {
            id
            label
          }
          one_time_bonus_burden_cents
          pay_per_week_cents
          cancellation_policy
          contract_total_hours
          guaranteed_hours_string
          missing_condition_ids
        }
      `,
    };
  }

 // return gql query with fragment (if exists)
  return gql`
    ${GetAvailableAssignmentsFragment.fragment}

    query Assignments($input: AssignmentQueryInput) {
        availableAssignments(input: $input) {
          ${GetAvailableAssignmentsFragment.name}
          id
          starts_date
          ends_date
          starts_time
          ends_time
          hours_per_shift
          number_of_shifts_per_week
          covid_care
          is_w2
        }
        _availableAssignmentsMeta(input: $input) {
          count
        }
      }
  `;
}

export const getFavoriteAssignmentsGQL = ({ account }: AssignmentQueriesProps) => {
  let GetFavoriteAssignmentsFragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  // make appropriate fragment based on account type
  if (!isAgencyAccount(account)) {
    GetFavoriteAssignmentsFragment = {
      name: '...GetFavoriteAssignmentsFragment',
      fragment: gql`
        fragment GetFavoriteAssignmentsFragment on ProfessionalAssignmentFavorite {
          id
        }
      `,
    };
  }

   // return gql query with fragment (if exists)
  return gql`
    ${GetFavoriteAssignmentsFragment.fragment}

    query FavoritedAssignments(
      $input: ProfessionalAssignmentFavoriteQueryInput
    ) {
      professionalAssignmentFavorites(input: $input) {
        ${GetFavoriteAssignmentsFragment.name}
        id
        assignment_id
        assignment {
          id
          is_w2
          is_travel
          starts_date
          ends_date
          status
          call_requirements
          positions {
            display_name
          }
          primary_location {
            id
            name
            address_first
            address_second
            address_city
            address_zip
            state {
              abbreviation
            }
            timezone_lookup
          }
          pay_per_week_cents
          hours_per_shift
          number_of_shifts_per_week
          starts_time
          ends_time
          specialties {
            id
            label
          }
          one_time_bonus_burden_cents
          first_to_apply
          distance_in_miles
          less_than_300_miles_away
          great_pay
          missing_condition_ids
        }
      }
    }
  `;
}

export const getAssignmentGQL= ({ account }: AssignmentQueriesProps) => {
  let GetAssignmentFragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  // make appropriate fragment based on account type
  if (!isAgencyAccount(account)) {
    GetAssignmentFragment = {
      name: '...GetAssignmentFragment',
      fragment: gql`
        fragment GetAssignmentFragment on Assignment {
          id
        }
      `,
    };
  }

   // return gql query with fragment (if exists)
  return gql`
    ${GetAssignmentFragment.fragment}

    query Assignment($id: Int!) {
      assignment(id: $id) {
        ${GetAssignmentFragment.name}
        id
        covid_care
        professional {
          account {
            first_name
            last_name
          }
        }
        facility_number
        professional_number
        clinical_contact {
          first_name
          last_name
        }
        status
        created_at
        starts_date
        ends_date
        charge_base_hourly_rate_cents
        positions {
          id
          kind
          display_name
          name
        }
        surgery_types {
          id
          display_name
        }
        specialties {
          id
          label
        }
        status
        locations {
          id
          name
          timezone_lookup
        }
        primary_location {
          id
          name
          address_first
          address_second
          address_city
          address_zip
          state {
            abbreviation
          }
          location_type {
            label
          }
          parking_instructions
          parking_free
          parking_reimbursed
          parking_reimbursement_cents
          parking_validated
          parking_cost_cents
          timezone_lookup
          market_id
          market {
            display_name
          }
          drug_screening_required
        }
        hours_per_shift
        starts_time
        ends_time
        is_extension
        number_of_shifts_per_week
        schedule_type
        patient_population
        scrubs_provided
        jobs_detail
        paused
        is_w2
        is_travel
        clinical_contact_account_id
        clinical_contact {
          id
        }
        one_time_bonus_burden_cents
        payout_taxable_hourly_rate_cents
        payout_overtime_multiplier
        payout_on_call_hourly_rate_cents
        mie_per_diem_cents
        lodging_per_diem_cents
        pay_per_week_cents
        referral_reward_cents
        nontaxable_amount_cents
        taxable_amount_cents
        flexible_starts_date
        variable_shift_times
        per_diem
        guaranteed_minimum_hours
        enforce_guaranteed_minimum_hours
        estimated_unpaid_break_time_minutes
        track_unpaid_breaks
        can_apply
        min_years_of_experience
        mileage_requirement
        ehr_system {
          id
          label
        }
        onboarding_due_date
        cancellation_policy
        contract_total_hours
        guaranteed_hours_string
        posting_field_contents
        first_to_apply
        distance_in_miles
        less_than_300_miles_away
        great_pay
        missing_condition_ids
        vms_partnership
      }
    }
  `;
}

export const getAvailableAssignmentGQL = ({ account }: AssignmentQueriesProps) => {
  let GetAvailableAssignment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  // make appropriate fragment based on account type
  if (!isAgencyAccount(account)) {
    GetAvailableAssignment = {
      name: '...GetAvailableAssignment',
      fragment: gql`
        fragment GetAvailableAssignment on Assignment {
          id
        }
      `,
    };
  }

   // return gql query with fragment (if exists)
  return gql`
    ${GetAvailableAssignment.fragment}

    query AvailableAssignment($id: Int!) {
      availableAssignment(id: $id) {
        ${GetAvailableAssignment.name}
        id
        covid_care
        call_requirements
        professional {
          account {
            first_name
            last_name
          }
        }
        professional_number
        clinical_contact {
          first_name
          last_name
        }
        facility_number
        status
        created_at
        starts_date
        ends_date
        charge_base_hourly_rate_cents
        positions {
          id
          kind
          display_name
          name
        }
        surgery_types {
          id
          display_name
        }
        specialties {
          id
          label
        }
        status
        locations {
          id
          name
          timezone_lookup
        }
        primary_location {
          id
          name
          address_first
          address_second
          address_city
          address_zip
          state {
            abbreviation
          }
          location_type {
            label
          }
          parking_instructions
          parking_free
          parking_reimbursed
          parking_reimbursement_cents
          parking_validated
          parking_cost_cents
          timezone_lookup
          market_id
          market {
            display_name
          }
          drug_screening_required
          phone_number
          state_id
        }
        hours_per_shift
        starts_time
        ends_time
        is_extension
        number_of_shifts_per_week
        schedule_type
        patient_population
        scrubs_provided
        jobs_detail
        paused
        is_w2
        is_travel
        clinical_contact_account_id
        clinical_contact {
          id
        }
        one_time_bonus_burden_cents
        payout_taxable_hourly_rate_cents
        payout_overtime_multiplier
        payout_on_call_hourly_rate_cents
        mie_per_diem_cents
        lodging_per_diem_cents
        pay_per_week_cents
        referral_reward_cents
        nontaxable_amount_cents
        taxable_amount_cents
        flexible_starts_date
        variable_shift_times
        per_diem
        guaranteed_minimum_hours
        enforce_guaranteed_minimum_hours
        estimated_unpaid_break_time_minutes
        track_unpaid_breaks
        can_apply
        min_years_of_experience
        mileage_requirement
        ehr_system {
          id
          label
        }
        cancellation_policy
        contract_total_hours
        guaranteed_hours_string
        posting_field_contents
        first_to_apply
        distance_in_miles
        less_than_300_miles_away
        great_pay
        missing_condition_ids
        vms_partnership
      }
    }
  `;
}

export const getAssignmentMetaGQL = ({ account: _account }: AssignmentQueriesProps) => {
  // return gql query with fragment (if exists)
  return gql`
    query AssignmentMeta($id: Int!) {
      _assignmentMeta(id: $id) {
        is_travel
        is_w2
        local_only
      }
    }
  `;
}
