import React from 'react';
import {
  Box,
  Button,
  Heading,
  HStack,
  MultiSelectField,
  MultiSelectFieldProps,
  VStack,
} from '@medely/ui-kit';
import {
  PositionsDetailsModalButton,
  PositionsDetailsModalButtonProps,
} from './components/PositionsDetailsModalButton';
import { FixedFooter } from '../../components/FixedFooterLayout/InternalFixedFooter';

type PositionsSelectProps = {
  allPositions: PositionsDetailsModalButtonProps['positions'];
  selectedPositions?: MultiSelectFieldProps['options'];
  handleContinueClick: () => void;
  handleBackClick: () => void;
};

export const PositionsSelect = ({
  allPositions,
  selectedPositions,
  handleContinueClick,
  handleBackClick,
}: PositionsSelectProps) => {
  const handlePositionsData = () => {
    const parsedAllPositions: MultiSelectFieldProps['options'] = [];
    allPositions.map((position) => {
      const newPosition = { value: position.id, label: position.name };
      parsedAllPositions.push(newPosition);
    });
    return parsedAllPositions;
  };

  const options = selectedPositions ?? handlePositionsData();

  return (
    <Box>
      <Heading size="s" color="text.primary">
        Select positions with 8+ months of paid clinical experience
      </Heading>
      <PositionsDetailsModalButton positions={allPositions} />
      <VStack gap={2}>
        <MultiSelectField
          variant="list"
          options={options}
          label="selectedPositions"
          name="selectedPositions"
        />
      </VStack>
      <FixedFooter>
        <HStack justifyContent="space-between">
          <Button fullWidth variant="outlined" onClick={handleBackClick}>
            Back
          </Button>
          <Box mx={1} />
          <Button fullWidth onClick={handleContinueClick}>
            Continue
          </Button>
        </HStack>
      </FixedFooter>
    </Box>
  );
};
